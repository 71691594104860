@import "layout-variables";

.accessibility-nav {
  display: block;
  list-style: none;
  position: relative;
  margin: 0;
  padding: 0;
  
  li {
    height: 0;
    margin: 0;
    padding: 0;
  }
  
  a {
    display: block;
    height: 0.0625rem;
    overflow: hidden;
    opacity: 0;
    position: absolute;
    text-align: center;
    text-decoration: underline;
    text-indent: 150%;
    top: -3.75rem;
    left: 0;
    transition: opacity 0.2s linear;
    white-space: nowrap;
    width: 0.0625rem;
    z-index: var(--zIndexGoToContent);

    @media (min-width: $xl) {
      top: 0;
    }
    &:focus {
      background: var(--backgroundGoToContent);
      font-size: 1.3125rem;
      height: auto;
      line-height: 1.3125rem;
      position: relative;
      font-weight: var(--font-weight-heavy);;
      color: var(--colorLinkBase);
      text-decoration: none;
      opacity: 1;
      outline: none;
      padding: 2.8125rem;
      text-indent: 0;
      width: 100%;
    }

    span {
      transition: border-bottom 0.5s;
      border-bottom: 2px solid var(--colorLinkBorder);
    }

    &:hover span {
      border-bottom: 2px solid var(--colorLinkBorderHover);
    }
  }
}